import './PuxButton.scss'

import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { FunctionComponent } from 'react'
import { getModalTriggerId } from 'Shared/components/PuxModal/PuxModal'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'

export enum PuxButtonColor {
  PRIMARY = `primary`,
  SECONDARY = `secondary`,
  CONVERSION = `conversion`,
}

export enum PuxButtonStyle {
  FILLED = `filled`,
  OUTLINED = `outlined`,
  LINK = `link`,
}

export interface PuxButtonType {
  buttonType: PuxButtonStyle | string
  buttonColor: PuxButtonColor | string
  buttonDisabled?: boolean
  buttonNewWindow?: boolean
  buttonOpenModalId?: string
  buttonLink: {
    url: [string]
    internal: [boolean]
    text: [string]
  }
}

const rootClass = `pux-button`

const PuxButton: FunctionComponent<PuxButtonType> = (props) => {
  const target = props.buttonNewWindow ? `_blank` : undefined
  const url = props.buttonLink?.url?.length && props.buttonLink.url[0]
  const text = props.buttonLink?.text?.length && props.buttonLink.text[0]

  if (!text) return null

  const colorClass = props.buttonColor
    ? props.buttonColor
    : PuxButtonColor.PRIMARY

  const styleClass = props.buttonType ? props.buttonType : PuxButtonStyle.FILLED

  const classes: string[] = [
    rootClass,
    `${rootClass}-${styleClass}`,
    `${rootClass}-${colorClass}`,
  ]

  if (props.buttonDisabled) {
    classes.push(`${rootClass}-disabled`)
  }

  const openModal = (event) => {
    event.preventDefault()
    const trigger = document.getElementById(
      getModalTriggerId(props.buttonOpenModalId)
    )
    if (trigger) {
      trigger.click()
    } else {
      console.log(
        `Modal error: Section-modal with ID "${props.buttonOpenModalId}" not found`
      )
    }
  }

  if (url && props.buttonLink.internal[0]) {
    return (
      <Link
        className={classes.join(` `)}
        to={`${getLocalizedUrl(url)}`}
        target={target}
        onClick={props.buttonOpenModalId ? openModal : undefined}
        data-open-modal-id={props.buttonOpenModalId || undefined}
      >
        {text}
      </Link>
    )
  } else {
    if (url && !url.includes(`#`)) {
      return (
        <a
          className={classes.join(` `)}
          href={url}
          target={target}
          rel={target === `_blank` ? `noopener` : undefined}
          onClick={props.buttonOpenModalId ? openModal : undefined}
          data-open-modal-id={props.buttonOpenModalId || undefined}
        >
          {text}
        </a>
      )
    } else if (url && url.includes(`#`)) {
      const location = useLocation()
      const pathname = location.pathname
      return (
        <AnchorLink
          className={classes.join(` `)}
          to={pathname + url}
          data-open-modal-id={props.buttonOpenModalId || undefined}
        >
          {text}
        </AnchorLink>
      )
    } else {
      return (
        <button
          type={`button`}
          className={classes.join(` `)}
          onClick={props.buttonOpenModalId ? openModal : undefined}
          data-open-modal-id={props.buttonOpenModalId}
        >
          {text}
        </button>
      )
    }
  }
}

export default PuxButton
